import React, { useEffect, useState } from 'react'
import { ApiUrl } from '../../../services/ApiRest';
import axios from 'axios';
import { useGetDate } from '../../../hooks/useGetDate';
import { ToastContainer, toast } from 'react-toastify';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';







export const ListCredit = () => {

  const { year, month, day } = useGetDate();
  const paddedMonth = String(month + 1).padStart(2, '0');
  const paddedDay = String(day).padStart(2, '0');
  const currentDate = `${year}-${paddedMonth}-${paddedDay}`;
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [credits, setcredits] = useState([]);
  const [selectedCredit, setselectedCredit] = useState([]);

  const [formD, setformD] = useState({
    ci: '',
    client_name: '',
    description: '',
    total_loan: '',
    state: 10,
    limit_date: currentDate,
    observation: '',
  });

  const getData = async () => {
    await axios.get(ApiUrl + "credit-list")
      .then(response => {
        const data = response.data;
        setcredits(data);

        //cargamos los datos nuevos
        const script = document.createElement("script");
        script.src = `/assets/dataTable/dataTable.js`;
        script.async = true;
        document.body.appendChild(script);
      })
      .catch(e => {
        console.log(e)
      })
  }

  const selectCredit = (data) => {
    console.log(data)
    setselectedCredit(data);
    setformD({
      ci: data.ci || '',
      client_name: data.client_name || '',
      description: data.description || '',
      total_loan: data.total_loan || '',
      state: data.state || 10,
      limit_date: data.limit_date || '',
      observation: data.observation || ''
    });
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    // notify();
    const f = new FormData();

    f.append("ci", formD.ci);
    f.append("client_name", formD.client_name);
    f.append("description", formD.description);
    f.append("total_loan", formD.total_loan);
    f.append("state", formD.state);
    f.append("limit_date", formD.limit_date);
    f.append("observation", formD.observation);
    console.log(Object.fromEntries(f));
    await axios.post(ApiUrl + 'credit-add', f)
      .then(response => {
        var resp = response.data;
        console.log(resp);
        if (resp.success) {
          toast.success("Credito Agregado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
          deleteTable();
          getData();
          setformD({
            ci: '',
            client_name: '',
            description: '',
            total_loan: '',
            state: 10,
            limit_date: '',
            observation: ''
          });
          // setcategorySelected('');
          // setsupplierSelected('');
        } else {
          toast.error("El crédito no se ha agregado", { position: toast.POSITION.BOTTOM_RIGHT });
        }
      })
      .catch(e => {
        console.log(e)
        toast.error("" + e + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
      })
      .finally(() => {
        setButtonDisabled(false); // Habilitar el botón después de que la solicitud haya finalizado
      });
  }
 
  const onSubmitEdit = async (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    // notify();
    const f = new FormData();

    f.append("ci", formD.ci);
    f.append("client_name", formD.client_name);
    f.append("description", formD.description);
    f.append("total_loan", formD.total_loan);
    f.append("state", formD.state);
    f.append("limit_date", formD.limit_date);
    f.append("observation", formD.observation);
    console.log(Object.fromEntries(f));
    await axios.post(ApiUrl + 'credit-update/'+ selectedCredit.credit_id, f)
      .then(response => {
        var resp = response.data;
        console.log(resp);
        if (resp.success) {
          toast.success("Credito Actualizado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
          deleteTable();
          getData();
          setformD({
            ci: '',
            client_name: '',
            description: '',
            total_loan: '',
            state: 10,
            limit_date: '',
            observation: ''
          });
          // setcategorySelected('');
          // setsupplierSelected('');
        } else {
          toast.error("El crédito no se ha editado", { position: toast.POSITION.BOTTOM_RIGHT });
        }
      })
      .catch(e => {
        console.log(e)
        toast.error("" + e + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
      })
      .finally(() => {
        setButtonDisabled(false); // Habilitar el botón después de que la solicitud haya finalizado
      });
  }

  const onInputChange = ({ target }) => {
    const { name, value } = target;
    setformD({
      ...formD,
      [name]: value
    })
  }

  const deleteCredit = async () => {
    await axios.post(ApiUrl + 'credit-delete/' + selectedCredit.credit_id)
      .then(resp => {
        deleteTable();
        getData();
        toast.success("Crédito Eliminado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });

      })
      .catch(e => {
        console.log(e);
      })
  }


  const deleteTable = () => {
    const script1 = document.createElement("script");
    script1.src = `/assets/dataTable/datatable-destroy.js`;
    script1.async = true;
    document.body.appendChild(script1);
  }

  useEffect(() => {
    getData();

  }, [])
  return (
    <div>
      <div className='row'>
        <div className='col-12 '>
          <div className="card">
            <div className="card-header">
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <b>LISTA DE CRÉDITOS</b>
                </div>
                <div className='col-12 col-md-6 ' style={{ textAlign: 'right' }}>
                  <button className='btn btn-success btn-sm' data-bs-toggle="modal" data-bs-target="#addCreditModal" ><b>CREAR CRÉDITO </b><i className="fa fa-plus" aria-hidden="true"></i></button>
                </div>
              </div>
            </div>
            <div className="card-body table-responsive">
              <table className='table table-hover' id="dataTable" style={{ width: '100%' }} >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Cédula</th>
                    <th>Nombre y Ape.</th>
                    <th>Descripción</th>
                    <th>Total</th>
                    <th>Fecha de crédito</th>
                    <th>Estado</th>
                    {/* <th>Observación</th> */}
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {credits.map((credit, index) => (
                    <tr key={credit.credit_id}>
                      <td>{index + 1}</td>
                      <td>{credit.ci}</td>
                      <td>{credit.client_name}</td>
                      <td>{credit.description}</td>
                      <td>{credit.total_loan}</td>
                      <td>{credit.created_at}</td>
                      {/* <td>{credit.status_description}</td> */}
                      <td >
                        <p className={`
                            ${credit.status_description == 'Pagado' ? 'badge rounded-pill bg-success .large-text' : ''
                          }`}
                        >
                          {credit.status_description}
                        </p>
                      </td>
                      <td>

                        <button className='btn btn-outline-primary' data-bs-toggle="modal" data-bs-target="#editCreditModal" onClick={() =>selectCredit(credit) }><i className="fas fa-pen" aria-hidden="true"></i></button>

                        <button className='btn btn-outline-danger' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => selectCredit(credit)}><DeleteOutlinedIcon /></button>

                      </td>

                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Eliminar</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                Está seguro que desea eliminar el crédito de <b>{selectedCredit.client_name}</b><br></br>
                Con el valor <b>{selectedCredit.total_loan}</b><br></br><br></br>
                {selectedCredit.description}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => deleteCredit()} data-bs-dismiss="modal" >Aceptar</button>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cancelar</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <ToastContainer theme="colored" /> */}
      <div className="modal fade" id="addCreditModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"><b>CREAR CRÉDITO </b></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={onSubmit}>
                <div className='row'>
                  <div className='col-6 col-md-6'>
                    <div className="">
                      <label className="form-label">Cédula</label>
                      <input type="text" name='ci' className="form-control" value={formD.ci} onChange={onInputChange}  ></input>
                    </div>
                  </div>

                  <div className='col-6 col-md-6'>
                    <div className="">
                      <label className="form-label">Nombre y Apellido</label>
                      <input type="text" name='client_name' className="form-control" value={formD.client_name} onChange={onInputChange} required ></input>

                    </div>
                  </div>
                  <div className='col-12 col-md-12'>
                    <div className="">
                      <label className="form-label">Descripción</label>
                      <input type="text" name='description' className="form-control" value={formD.description} onChange={onInputChange} required ></input>

                    </div>
                  </div>
                  <div className='col-4 col-md-3'>
                    <div className="">
                      <label className="form-label">Total</label>
                      <input type="text" className="form-control" name="total_loan" value={formD.total_loan} onChange={onInputChange} required ></input>
                    </div>
                  </div>

                  <div className='col-4 col-md-3'>
                    <div className="">
                      <label className="form-label">Estado de crédito</label>
                      <select className="form-select" name='state' onChange={onInputChange} value={formD.state} required aria-label=".form-select-sm example">
                        <option value="10" >No Pagado</option>
                        <option value="11" >Pagado</option>
                      </select>
                      {/* <input type="text" className="form-control" name="selling_price" value={formD.selling_price} onChange={onInputChange} required></input> */}
                    </div>
                  </div>

                  <div className='col-4 col-md-3'>
                    <div className="">
                      <label className="form-label">Fecha de crédito</label>
                      <input type="date" className="form-control" name='limit_date' value={formD.limit_date} onChange={onInputChange} required></input>
                    </div>
                  </div>

                  <div className='col-12 col-sm-12'>
                    <div className="form-group">
                      <label > Observaciones</label>
                      <input type="text" name='observation' className="form-control" value={formD.observation} onChange={onInputChange} placeholder=''></input>
                    </div>
                  </div>
                </div>

                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button type="submit" className="btn btn-success" disabled={buttonDisabled}>Guardar</button>
                </div>
              </form>

            </div>

          </div>
        </div>
      </div>
      
      
      <div className="modal fade" id="editCreditModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"><b>EDITAR CRÉDITO </b></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={onSubmitEdit}>
                <div className='row'>
                  <div className='col-6 col-md-6'>
                    <div className="">
                      <label className="form-label">Cédula</label>
                      <input type="text" name='ci' className="form-control"  value={formD.ci || ''}  onChange={onInputChange}  ></input>
                    </div>
                  </div>

                  <div className='col-6 col-md-6'>
                    <div className="">
                      <label className="form-label">Nombre y Apellido</label>
                      <input type="text" name='client_name' className="form-control" value={formD.client_name || ''} onChange={onInputChange} required ></input>

                    </div>
                  </div>
                  <div className='col-12 col-md-12'>
                    <div className="">
                      <label className="form-label">Descripción</label>
                      <input type="text" name='description' className="form-control" value={formD.description || ''} onChange={onInputChange} required ></input>

                    </div>
                  </div>
                  <div className='col-4 col-md-3'>
                    <div className="">
                      <label className="form-label">Total</label>
                      <input type="text" className="form-control" name="total_loan" value={formD.total_loan || ''} onChange={onInputChange} required ></input>
                    </div>
                  </div>

                  <div className='col-4 col-md-3'>
                    <div className="">
                      <label className="form-label">Estado de crédito</label>
                      <select className="form-select" name='state' onChange={onInputChange} value={formD.state || ''} required aria-label=".form-select-sm example">
                        <option value="10" >No Pagado</option>
                        <option value="11" >Pagado</option>
                      </select>
                      {/* <input type="text" className="form-control" name="selling_price" value={formD.selling_price} onChange={onInputChange} required></input> */}
                    </div>
                  </div>

                  <div className='col-4 col-md-3'>
                    <div className="">
                      <label className="form-label">Fecha de crédito</label>
                      <input type="date" className="form-control" name='limit_date' value={formD.limit_date || ''} onChange={onInputChange} required></input>
                    </div>
                  </div>

                  <div className='col-12 col-sm-12'>
                    <div className="form-group">
                      <label > Observaciones</label>
                      <input type="text" name='observation' className="form-control" value={formD.observation || ''} onChange={onInputChange} placeholder=''></input>
                    </div>
                  </div>
                </div>

                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button type="submit" className="btn btn-success" disabled={buttonDisabled}>Guardar</button>
                </div>
              </form>

            </div>

          </div>
        </div>
      </div>

      <ToastContainer theme="colored" />


    </div>
  )
}
