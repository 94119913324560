import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ApiUrl } from '../../../services/ApiRest';
import { ToastContainer, toast } from 'react-toastify';

export const SuppliersList = () => {
    const [data, setdata] = useState([]);
    const [supplierId, setsupplierId] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [supplierName, setsupplierName] = useState();
    const [formD, setformD] = useState({
        suppliers_id: null,
        name_store: '',
        phone: '',
        address: '',
        owner: '',
    });

    const dataListSuppliers = async () => {
        try {
            const response = await axios.get(ApiUrl + `suppliers-list`);
            const responseData = response.data;
            setdata(responseData);
            console.log('Todas la Suppliers:', responseData);
            const script = document.createElement("script");
            script.src = `/assets/dataTable/dataTable.js`;
            script.async = true;
            document.body.appendChild(script);
        } catch (error) {
            console.error(error);
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        setButtonDisabled(true);
        notify();
        const formData = new FormData();
        formData.append("name_store", formD.name_store);
        formData.append("phone", formD.phone);
        formData.append("address", formD.address);
        formData.append("owner", formD.owner);
        try {
            const response = await axios.post(ApiUrl + 'suppliers-add', formData);
            const resp = response.data;
            console.log('Valor de RESP cuando existe o no el Regidtro', resp);
            if (resp.success===true) {
                toast.success("Proveedor agregado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataListSuppliers();
                setformD({
                    name_store: '',
                    phone: '',
                    address: '',
                    owner: ''
                });
            } else {
                toast.error("Proveedor no se ha agregado, porque ya existe", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        } catch (error) {
            console.log(error);
            toast.error("" + error + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
        } finally {
            setButtonDisabled(false); // Habilitar el botón después de que la solicitud haya finalizado
        }


    };

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setformD({
            ...formD,
            [name]: value
        })
    }

    const onSubmitUpdate = async (event) => {
        event.preventDefault();

        const nameStoreConvert = formD.name_store.trim(); // Eliminar espacios en blanco al principio y al final
        // Expresión regular para validar la entrada del usuario
        const addressConvert = formD.address.trim();
        const ownerConvert = formD.owner.trim();
        const regex = /^[A-Za-záéíóúüñÁÉÍÓÚÜÑ\s\d\{\}]+$/;
        if (!nameStoreConvert.match(regex) || !addressConvert.match(regex) || !ownerConvert.match(regex)) {
            // Si la descripción no coincide con la expresión regular, mostrar un mensaje de error
            toast.error("La descripción contiene caracteres no válidos", { position: toast.POSITION.BOTTOM_RIGHT });
            setButtonDisabled(false);
            return; // Salir de la función
        }

        const formData = new FormData();
        formData.append("name_store", formD.name_store);
        formData.append("phone", formD.phone);
        formData.append("address", formD.address);
        formData.append("owner", formD.owner);
        try {
            const response = await axios.post(ApiUrl + `suppliers-update/${formD.suppliers_id}`, formData);
            const resp = response.data;
            if (resp.success) {
                toast.success("Proveedor actualizado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataListSuppliers();
            } else {
                toast.error("Proveedor no se ha actualizado", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        } catch (error) {
            console.log(error);
            toast.error("" + error + "  !", { position: toast.POSITION.BOTTOM_RIGHT });
        } finally {
            setButtonDisabled(false); // Habilitar el botón después de que la solicitud haya finalizado
        };
    };

    const deleteSupplier = async (supplier_id) => {
        try {
            const response = await axios.post(ApiUrl + `suppliers-delete/${supplier_id}`);
            const resp = response.data;
            console.log('Saber mensaje que regresa en RESP:', resp);
            console.log('Saber mensaje que regresa en RESP:', resp.success);
            if (resp.success === true) {
                toast.success("Proveedor eliminado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });
                dataListSuppliers();
            } else {
                // toast.error("El proveedor no puede ser eliminado porque está asociado a productos", { position: toast.POSITION.BOTTOM_RIGHT });
                toast.error("Existen productos asociados al proveedor seleccionado, por favor elimine los productos asociados con el proveedor seleccionado en el inventario para poder continuar", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        } catch (error) {
            console.log(error);
            toast.error("" + error + "!", { position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    const toastId = React.useRef(null);
    const notify = () => toastId.current = toast("Cargando Datos...", { autoClose: true, type: toast.TYPE.INFO, position: toast.POSITION.BOTTOM_RIGHT });
    const success = (messagge) => toast.success("Registro agregado exitosamente", { position: toast.POSITION.BOTTOM_RIGHT });

    useEffect(() => {
        dataListSuppliers();
    }, [])

    return (
        <div>
            <div className='row'>
                <div className='col-12 '>
                    <div className="card">
                        <div className=" card-header">
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <b>LISTA DE PROOVEDORES</b>
                                </div>
                                <div className='col-12 col-md-6 ' style={{ textAlign: 'right' }}>
                                    <button className='btn btn-success btn-sm' data-bs-toggle="modal" data-bs-target="#addModal" ><b>CREAR PROVEEDORES</b><i className="fa fa-plus" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <table className='table table-hover ' id="dataTable"  >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre</th>
                                        <th>Direccíon</th>
                                        <th>Propietario</th>
                                        <th>Teléfono</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((dataSupplier, i) => (
                                        <tr key={dataSupplier.suppliers_id}>
                                            <td>{i + 1}</td>
                                            <td>{dataSupplier.name_store}</td>
                                            <td>{dataSupplier.address}</td>
                                            <td>{dataSupplier.owner}</td>
                                            <td>{dataSupplier.phone}</td>
                                            <td>
                                                <button className='btn btn-outline-primary' data-bs-toggle="modal" data-bs-target="#editProductModal" onClick={() => { setformD({ suppliers_id: dataSupplier.suppliers_id, name_store: dataSupplier.name_store, address: dataSupplier.address, owner: dataSupplier.owner, phone: dataSupplier.phone }); }}><i className="fas fa-pen" aria-hidden="true"></i></button>
                                                <button className='btn btn-outline-danger' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => { setsupplierId(dataSupplier.suppliers_id); setsupplierName(dataSupplier.name_store); }} ><i className="fas fa-trash-alt" aria-hidden="true"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* ELIMINAR PROVEEEDOR */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">ELIMINAR PROVEEEDOR</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            ¿Está seguro que desea eliminar<b> {supplierName}? </b>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteSupplier(supplierId)}>Aceptar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* CREAR PROVEEEDOR */}
            <div className="modal fade" id="addModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><b>CREAR PROVEEEDOR</b></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={onSubmit}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="">
                                            <label className="form-label">Nombre:</label>
                                            <input type="text" name='name_store' className="form-control" value={formD.name_store} onChange={onInputChange} required ></input>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="">
                                            <label className="form-label">Dirección</label>
                                            <input type="text" name='address' className="form-control" value={formD.address} onChange={onInputChange} required ></input>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="">
                                            <label className="form-label">Propietario</label>
                                            <input type="text" name='owner' className="form-control" value={formD.owner} onChange={onInputChange} required ></input>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="">
                                            <label className="form-label">Teléfono</label>
                                            <input type="text" name='phone' className="form-control" value={formD.phone} onChange={onInputChange} required ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    <button type="submit" className="btn btn-success" disabled={buttonDisabled}>Guardar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* EDITAR PROVEEEDOR */}
            <div className="modal fade" id="editProductModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"><b>EDITAR PROVEEEDOR</b></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={onSubmitUpdate}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="">
                                            <label className="form-label">Nombre</label>
                                            <input type="text" name='name_store' className="form-control" value={formD.name_store !== null && formD.name_store !== undefined ? formD.name_store : ''} onChange={onInputChange} required></input>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="">
                                            <label className="form-label">Dirección</label>
                                            <input type="text" name='address' className="form-control" value={formD.address !== null && formD.address !== undefined ? formD.address : ''} onChange={onInputChange} required ></input>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="">
                                            <label className="form-label">Propietario</label>
                                            <input type="text" name='owner' className="form-control" value={formD.owner !== null && formD.owner !== undefined ? formD.owner : ''} onChange={onInputChange} required ></input>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="">
                                            <label className="form-label">Teléfono</label>
                                            <input type="text" name='phone' className="form-control" value={formD.phone !== null && formD.phone !== undefined ? formD.phone : ''} onChange={onInputChange} required ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    <button type="submit" className="btn btn-success" data-bs-dismiss="modal" disabled={buttonDisabled}>Guardar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    )
}