// PRODUCTION FULL MIHOSTING.COM
// export const ApiUrl = "https://cch-back.softcodi.com/public/api/";
// export const ApiStorage = "https://cch-back.softcodi.com/storage/app/public/";

// PRODUCTION INVENTORY MIHOSTING.COM
// export const ApiUrl = "https://pqt-back.softcodi.com/public/api/";
// export const ApiStorage = "https://pqt-back.softcodi.com/storage/app/public/";

export const ApiUrl = "https://cch-back.softcodi.com/public/api/";
export const ApiStorage = "https://cch-back.softcodi.com/storage/app/public/";


// PRODUCTION AWS
// export const ApiUrl = "https://backend.campingchimborazo.com/CCH-BACK-PSP/public/api/";
// export const ApiStorage = "https://backend.campingchimborazo.com/CCH-BACK-PSP/storage/app/public/";


//PRUEBAS
// export const ApiUrl = "http://127.0.0.1:8000/api/";
// export const ApiStorage = "http://127.0.0.1:8000/storage/";
