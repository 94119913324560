import axios from 'axios';
import React from 'react'
import { ApiUrl } from '../services/ApiRest';



export const requestEnterprise = async () => {
    var result={};

    await axios.get(ApiUrl + 'enterprise')
    .then(resp => {
        const responseData = resp.data;
        // console.log("enterprise LISTO", responseData[0].software_type_id);
        // setswType(responseData[0].software_type_id);
        result= responseData[0];
    })
    .catch(e => {
        console.log(e);
    })
  return ( result )
}
