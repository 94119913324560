import React, { useEffect, useState } from 'react'
import ReactLoading from "react-loading";


export const PreLoader = () => {
    return (
        <div style={fullScreenContainer}>
                <ReactLoading
                    type={"spin"}
                    color={"white"}
                    height={100}
                    width={100}
                />
        </div>
    );
}


const fullScreenContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw', // 100% of the viewport width
    height: '100vh', // 100% of the viewport height
    position: 'fixed', // Makes sure it covers the whole screen
    top: 0,
    left: 0,
    zIndex: 9999, // Ensures it is on top
    backgroundColor:"#2e3638"
};