import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useGetDate } from '../../hooks/useGetDate'
import { useSetMonthlyTour } from '../../hooks/useSetMonthlyTour';
import { ApiStorage, ApiUrl } from '../../services/ApiRest';
import { FooterScreen } from '../footer/FooterScreen';
import { NavBarScreen } from '../navBar/NavBarScreen'
import { WhatsappButtonScreen } from './whatsappButton/WhatsappButtonScreen';
import { useGetDateToDayMonth } from '../../hooks/useGetDateToDayMonth';

export const CuevaTayosScreen = () => {
  localStorage.removeItem("menu");
  window.localStorage.setItem("menu", 9);

  const { printMonth, printNextMonth, date } = useGetDate();

  const [tours, settours] = useState([]);

  const getData = async () => {
    await axios.get(ApiUrl + "monthly-tour-list")
      .then(response => {
        const data = response.data;
        settours(data);
      })
      .catch(e => {
        console.log(e);
      })
  }

  const convertDate = (date) => {
    const { letterMonth, day } = useGetDateToDayMonth(date);
    var dateText = day + ' ' + 'DE ' + letterMonth;
    return (dateText);
  }

  useEffect(() => {
    getData();
  }, [])

  const { monthlyTour, nextMonthlyTour } = useSetMonthlyTour(tours);
  console.log(nextMonthlyTour);

  return (
    <>
      <NavBarScreen />

      <div className="cont_img animate__animated  animate__fadeIn" >
        <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
          <div className="carousel-inner" role="listbox">

            <div className="banner" data-bs-interval="4000" style={{ backgroundImage: `url('./assets/images/principal/cueva/b.jpeg')` }}>
              <div className="pantalla_dividida" style={{ height: "45vh" }}>
                <div className="text_cent_img_dividida animate__animated animate__fadeInRight animate__delay-0.8s">
                  <h1 className="tit-sob-img_dividida" style={{ textShadow: '1px 1px 2px rgba(0,0,0,1.5)', letterSpacing: '0px' }}>Explora la<br />Cueva de los Tayos </h1>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      {/* <div className='container'>
        <h5 style={{ textAlign: 'center' }}>¡Descubre la maravillosa aventura que te espera en Perú! <br></br>Te ofrecemos emocionantes paquetes de viaje para que explores los magníficos atractivos de este hermoso país. Desde la majestuosidad de Machu Picchu hasta la impresionante montaña de 7 colores y la serenidad de la laguna de Humantay, hay un mundo de experiencias esperándote..</h5>
        <br></br>

      </div> */}


      <br></br>
      <div className='container'>
        <h1 className="camping-letters text-center" >3 DÍAS Y DOS NOCHES DE EXPLORACIÓN</h1>
        <h4 className="camping-letters text-center" >1 - 2 Y 3 DE NOVIEMBRE</h4>
        {/* <h1 className="tit-sob-img_dividida blink text-center" style={{ textShadow: '1px 1px 2px rgba(0,0,0,1.5)', letterSpacing: '0px', color: '#28a745' }}>
          1-2 Y 3 DE NOVIEMBRE
        </h1> */}

      </div>


      <div className='container pantalla-grande'>
        <br></br>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-6'>
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item carousel-item2 active">
                  {/* <img src={`${ApiStorage + tour.img_1}`} style={{ width: '100%' }} className="card-img-top" alt="..."></img> */}
                  <img src={`./assets/images/principal/cueva/ct1.png`} className="rounded img-fluid img-thumbnail" alt="" width="100%" height="auto" />

                </div>
                <div className="carousel-item carousel-item2 ">
                  {/* <img src={`${ApiStorage + tour.img_2}`} style={{ width: '100%' }} className="card-img-top" alt="..."></img> */}
                  <img src={`./assets/images/principal/cueva/ct2.png`} className="rounded img-fluid img-thumbnail" alt="" width="100%" height="auto" />

                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-6'>
            <br></br>
            <div>

            </div>



            <p className='text-justify text-center'>
              ¿Estás listo para una aventura única que solo unos pocos han experimentado? Te invitamos a descubrir la mística Cueva de los Tayos en un recorrido de 3 días y 2 noches, donde vivirás la naturaleza y el misterio en su forma más pura
            </p>
            <p className='text-justify text-center'><b className='text-justify '>¿Qué te espera?</b></p>
            <p>
              <li>Exploración a la Cueva de los Tayos: Adéntrate en una de las cuevas más legendarias del mundo, famosa por su historia, belleza natural y misterios aún por descubrir. Acompañado de guías locales expertos, explorarás sus profundidades como nunca antes.</li>
              <br></br><li>Cultura Shuar: Conoce de primera mano las tradiciones y costumbres de la comunidad Shuar, quienes serán tus anfitriones en esta travesía. Vive una conexión auténtica con su cultura, sus rituales y su sabiduría ancestral.</li>
              <br></br><li>Noche dentro de la Cueva: ¡Sí! Pasarás una noche mágica en el interior de la cueva, rodeado de un entorno lleno de historia, silencio y naturaleza pura. Una experiencia incomparable que te dejará asombrado</li>
              <br></br><li>Aventura y Naturaleza: A lo largo del recorrido, disfrutarás de impresionantes paisajes amazónicos, navegando ríos y caminando entre la flora y fauna más diversa del Ecuador.</li>
              <br></br>
            </p>
            <br></br>
            <div className='text-justify text-center'>
              <a href="https://wa.link/rataqq" target="_blank" style={{ textDecoration: 'none' }}>
                <button type="button" style={{ width: "50%", margin: "5px" }} className="btn btn-success">
                  <b>CLIC AQUÍ PARA MÁS INFO<i className="fab fa-whatsapp fa-1x"  ></i></b>
                </button>
              </a>
            </div>


            <br></br>

            {/* <div className='row text-center'>
                                <div className='col-12 col-sm-6'>
                                    <h4 className='camping-letters' style={{ textTransform: 'uppercase' }}>Fecha Salida<br></br> </h4>
                                    <h5>{convertDate(tour.departure_date)}</h5>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <h4 className='camping-letters' style={{ textTransform: 'uppercase' }}>Fecha Retorno <br></br> </h4>
                                    <h5>{convertDate(tour.return_date)}</h5>

                                </div>
                            </div> */}

            <br></br>
            {/* <div className='container text-center'>
                                <a href={`https://api.whatsapp.com/send?phone=+593${tour.contact_phone}&text=${tour.messagge_for_contact}`} target="_blank" style={{ padding: '5px' }}>
                                    <button type="button" className="btn btn-success" style={{ margin: '2vh' }}> COMPRAR  RUTA </button >
                                </a>

                                <Link to={'/tour-mensual'} style={{ padding: '5px' }}>
                                    <button type="button" className="btn btn-warning" >VOLVER A TOURS</button>
                                </Link>
                            </div> */}
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Día 1</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Día 2</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Día 3</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="include-tab" data-bs-toggle="tab" data-bs-target="#include" type="button" role="tab" aria-controls="contact" aria-selected="false">Incluye</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="no-include-tab" data-bs-toggle="tab" data-bs-target="#no-include" type="button" role="tab" aria-controls="contact" aria-selected="false">No Incluye</button>
          </li>
        </ul>


        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <br></br>
            <p></p>
            <p>
              <h4 className='text-justify text-center'>INGRESO A LA SELVA</h4>
              <p className='text-justify text-center'>¿Te imaginas adentrarte en el corazón de la selva y descubrir sus secretos mejor guardados? Este paquete te ofrece una
                aventura única que conecta la naturaleza, la cultura ancestral y el misticismo en un solo viaje. Aquí tienes un adelanto de lo que te espera:
              </p>
              <b>Conexión con la Naturaleza desde el Primer Momento</b>
              <br></br>El día comienza temprano, reuniéndonos en el parque central de Méndez, donde te unirás a un grupo de aventureros como tú, listos para vivir una
              experiencia inolvidable. Desde allí, nos dirigimos a Yuquianza, atravesando paisajes majestuosos, hasta llegar a la comunidad indígena Yukiankas.
              <br></br>
              <br></br>
              <b>Inmersión Cultural y Natural</b>
              <br></br>Llegarás a Kiankus, donde montarás tu campamento en medio de la naturaleza. Aquí, sentirás la conexión total con la selva, respirando aire puro,
              rodeado de sonidos de la vida silvestre, y compartiendo historias bajo un cielo estrellado.
              <br></br>
              <br></br>
              <b>Aventura en el Corazón de la Selva</b>
              <br></br>Navegarás por ríos hermosos, mientras la selva amazónica se despliega a tu alrededor en todo su esplendor. No es solo un viaje; es una
              verdadera inmersión en la vida amazónica, compartiendo momentos únicos con las comunidades locales y disfrutando de su hospitalidad.
              <br></br>
              <br></br>
              <b>Experiencia Espiritual (Opcional)</b>
              <br></br>Para los que buscan una experiencia más profunda, tendrás la opción de participar en un ritual de Ayahuasca, una ceremonia ancestral guiada
              por chamanes locales, que promete una conexión única con lo espiritual.



            </p>

          </div>
          <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

            <br></br>
            <p></p>
            <p>
              <h4 className='text-justify text-center'>¡Vive una Noche Mágica con el Descenso a la Cueva!</h4>
              <p className='text-justify text-center'>¿Estás listo para una aventura que te llevará más allá de lo convencional? El Descenso a la Cueva es una
                experiencia única que te conectará con la naturaleza, la cultura y la magia de la selva amazónica. Esto es lo que te espera:
              </p>
              <b>Montaje del Campamento</b>
              <br></br>Al llegar, te sumergirás en la naturaleza mientras montamos el campamento en un rincón especial de la selva. Aquí empieza la aventura, rodeado por un entorno poco visitado y lleno de vida.
              <br></br>
              <br></br>
              <b>Exploración a la Cueva</b>
              <br></br>Equipados con linternas, nos adentraremos en una cueva ancestral llena de misterio. Las formaciones naturales y el ambiente silencioso te harán sentir parte de un mundo escondido, donde solo los más aventureros se atreven a entrar.
              <br></br>
              <br></br>
              <b>Cena dentro de la Cueva</b>
              <br></br>Después de la exploración, te espera una cena inolvidable dentro de la cueva. Imagina disfrutar de una deliciosa comida en este entorno natural, mientras el eco de la cueva y la serenidad te rodean. ¡Es una experiencia que no encontrarás en ningún otro lugar!
              <br></br>
              <br></br>
              <b>Fogata Simbólica y Cantos Shuar</b>
              <br></br>Como cierre perfecto de esta jornada, nos reuniremos alrededor de una fogata simbólica dentro de la cueva, mientras los Shuar nos deleitan con sus cantos tradicionales. Este momento místico y cultural te permitirá sumergirte en sus costumbres ancestrales y crear una conexión profunda con la selva y su gente.

              <br></br>
              <br></br>
              <b>Descanso dentro de la Cueva</b>
              <br></br>Después de un día lleno de emociones, te relajarás y descansarás en el interior de la cueva, en un entorno seguro y lleno de paz, donde los sonidos de la naturaleza te arrullarán hasta dormir.



            </p>
          </div>
          <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
            <br></br>
            <p></p>
            <p>
              <h4 className='text-justify text-center'>¡Completa tu Aventura con el Ascenso de la Cueva!</h4>
              <p className='text-justify text-center'>¿Estás listo para la última etapa de esta increíble experiencia en la selva amazónica? El Ascenso de la Cueva no solo pondrá a prueba tus habilidades, sino que también te permitirá cerrar esta aventura con una conexión profunda con la naturaleza y la cultura local. Esto es lo que te espera:

              </p>
              <b>Desarmado del Campamento</b>
              <br></br>Comenzamos el día temprano, desarmando el campamento en plena selva. Mientras recogemos todo, podrás reflexionar sobre la experiencia vivida, preparándote para la gran actividad que te espera: ¡el ascenso de la cueva!
              <br></br>
              <br></br>
              <b>Desayuno Ligero</b>
              <br></br>Antes de emprender el desafío, disfrutarás de un desayuno ligero y nutritivo, ideal para cargar energías. Saborea los sabores frescos de la región mientras te preparas para lo que viene.
              <br></br>
              <br></br>
              <b>Equipamiento y Seguridad</b>
              <br></br>Con la guía de nuestros expertos, recibirás todo el equipo necesario para el ascenso y te brindaremos una charla de seguridad para garantizar que disfrutes cada momento de la aventura con total confianza.
              <br></br>
              <br></br>
              <b>El Gran Ascenso de la Cueva</b>
              <br></br>¡Aquí comienza la emoción! Atrévete a escalar las paredes naturales de la cueva, rodeado de un entorno majestuoso y lleno de adrenalina. Este desafío te llevará a nuevas alturas, literal y metafóricamente, mientras conquistas la cima y disfrutas de vistas espectaculares.
              <br></br>
              <br></br>
              <b>Exhibición de Artesanías Shuar y Regreso a Méndez</b>
              <br></br>Tras el ascenso, serás recibido por la comunidad Shuar, quienes te sorprenderán con una exhibición de sus bellas artesanías. Podrás conocer más sobre sus tradiciones, llevarte un recuerdo único y apoyar la cultura local.
              <br></br>Luego de un día lleno de emociones y descubrimientos, regresarás a Méndez con una experiencia inolvidable en tu corazón y una historia que contar.

              <br></br>
              <br></br>



            </p>
          </div>
          <div class="tab-pane fade" id="include" role="tabpanel" aria-labelledby="contact-tab">
            <br></br>
            <p></p>
            <p>
              <h4 className='text-justify text-center'>¡Aventura de 3 Días/2 Noches en la Selva Amazónica! 🌿🛶</h4>
              <p className='text-justify text-center'>¡Prepárate para una experiencia única que combina aventura, cultura y naturaleza en el corazón de la selva! Este paquete te ofrece todo lo que necesitas para disfrutar al máximo de esta increíble travesía. Esto es lo que incluye:

              </p>
              <b>🍽️ Alimentación Completa</b>
              <br></br>
              Saborea la gastronomía local con 6 deliciosas comidas:
              <li>Día 1: Desayuno, almuerzo y cena</li>
              <li>Día 2: Desayuno, almuerzo y cena</li>
              <li>Día 3: Desayuno ligero para recargar energías</li>
              <br></br>
              <b>🚐 Transporte desde Méndez (ida y regreso)</b>
              <br></br>
              El transporte está incluido desde el punto de encuentro en Méndez, para que disfrutes del viaje sin preocupaciones. ¡Solo relájate y deja que la aventura comience!
              <br></br>
              <br></br>
              <b>🛶 Transporte Fluvial</b>
              <br></br>
              Navega por los ríos amazónicos en una embarcación tradicional. La selva se despliega a tu alrededor mientras avanzamos hacia comunidades remotas.
              <br></br>
              <br></br>
              <b>🧢 Equipo Completo para el Ascenso</b>
              <br></br>
              Todo el equipo necesario para tu seguridad está incluido: casco y arnés. ¡Estás en buenas manos para una aventura segura y emocionante!
              <br></br>
              <br></br>
              <b>🗺️ Guía Local con 17 Años de Experiencia</b>
              <br></br>
              Con un guía local con más de 17 años de experiencia, te aseguras de explorar la selva de la manera más auténtica y segura. ¡Conoce historias, secretos y detalles únicos de la región!
              <br></br>
              <br></br>
              <b>📜 Permisos de Ingreso a las Comunidades</b>
              <br></br>
              Todo está gestionado para que puedas ingresar y convivir con las comunidades locales, respetando su cultura y costumbres.
              <br></br>
              <br></br>
              <b>⛺ Equipo de Camping Completo</b>
              <br></br>
              Dormirás cómodo en la selva con nuestro equipo de camping, que incluye:
              <li>Carpas compartidas para 3 personas</li>
              <li>Sleeping bag</li>
              <li>Aislante para una noche confortable</li>
              <br></br>

            </p>
          </div>
          <div class="tab-pane fade" id="no-include" role="tabpanel" aria-labelledby="contact-tab">
            <br></br>
            <p></p>
            <p>
              <h4 className='text-justify text-center'>¡Detalles Importantes! ❗️</h4>

              <b>NO INCLUYE</b>
              <br></br>
              <br></br>
              <li>🐎 Mulas para cargar tu equipo</li>
              <li>🍫 Snacks y bebidas durante el recorrido (lleva tus preferidos).</li>
              <li>🏕️ Carpas individuales (solo se incluyen carpas compartidas).</li>
              <li>🌿 Ceremonia de Ayahuasca (costo adicional).</li>
              <li>🚐 Transporte desde otras ciudades, como Riobamba. El punto de encuentro es en Méndez.</li>

              <br></br>
              <br></br>
              <b>Opciones Adicionales (con costo extra):</b>
              <br></br>
              <br></br>
              <li>🐴 Servicio de mulas: Si tu mochila es muy grande o pesada, puedes contratar el servicio de mulas por $50 (solo ida). ¡Una excelente opción si prefieres hacer el trayecto más ligero!</li>
              <li>🌌 Ceremonia de Ayahuasca: Si deseas participar en esta experiencia espiritual ancestral, el costo es de $60 por persona.</li>
              <li>🚶‍♂️ Camino corto para el regreso: Existe una opción más rápida para el regreso, por solo $6 por persona. Este valor no está incluido, ya que se paga directamente a los dueños del terreno. ¡Es completamente opcional y dependerá de lo que decida el grupo!</li>



            </p>
          </div>
        </div>


      </div>


      {/* *********PANTALLA PEQUE************* */}

      <div className='container pantalla-peque'>
        <br></br>
        <div className='col-12 col-sm-12 col-md-6'>
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item carousel-item2 active">
                {/* <img src={`${ApiStorage + tour.img_1}`} style={{ width: '100%' }} className="card-img-top" alt="..."></img> */}
                <img src={`./assets/images/principal/cueva/ct1.png`} className="rounded img-fluid img-thumbnail" alt="" width="100%" height="auto" />

              </div>
              <div className="carousel-item carousel-item2 ">
                {/* <img src={`${ApiStorage + tour.img_2}`} style={{ width: '100%' }} className="card-img-top" alt="..."></img> */}
                <img src={`./assets/images/principal/cueva/ct2.png`} className="rounded img-fluid img-thumbnail" alt="" width="100%" height="auto" />

              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <br></br>
        <div className='text-justify text-center'>
          <a href="https://wa.link/rataqq" target="_blank" style={{ textDecoration: 'none' }}>
            <button type="button" style={{ width: "50%", margin: "5px" }} className="btn btn-success">CLIC AQUÍ PARA MÁS INFO                <i className="fab fa-whatsapp fa-1x"  ></i>
            </button>
          </a>
        </div>


        <div className='col-12 col-sm-12 col-md-6 text-center'>
          <br></br>
          <div>

          </div>
          {/* <h3 className='camping-letters'> {tour.tour_name}</h3>
                            <h1 className='camping-letters' style={{ textTransform: 'uppercase' }}> {tour.tour_destiny}</h1>
                            <p className='text-justify text-center'>{tour.description}</p> */}

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-mov" type="button" role="tab" aria-controls="home" aria-selected="true">Día 1</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-mov" type="button" role="tab" aria-controls="profile" aria-selected="false">Día 2</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-mov" type="button" role="tab" aria-controls="contact" aria-selected="false">Día 3</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="include-tab" data-bs-toggle="tab" data-bs-target="#include-mov" type="button" role="tab" aria-controls="contact" aria-selected="false">Incluye</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="no-include-tab" data-bs-toggle="tab" data-bs-target="#no-include-mov" type="button" role="tab" aria-controls="contact" aria-selected="false">No Incluye</button>
            </li>
          </ul>


          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home-mov" role="tabpanel" aria-labelledby="home-tab">
              <br></br>
              <p></p>
              <p>
                <h4 className='text-justify text-center'>INGRESO A LA SELVA</h4>
                <p className='text-justify text-center'>¿Te imaginas adentrarte en el corazón de la selva y descubrir sus secretos mejor guardados? Este paquete te ofrece una
                  aventura única que conecta la naturaleza, la cultura ancestral y el misticismo en un solo viaje. Aquí tienes un adelanto de lo que te espera:
                </p>
                <b>Conexión con la Naturaleza desde el Primer Momento</b>
                <br></br>El día comienza temprano, reuniéndonos en el parque central de Méndez, donde te unirás a un grupo de aventureros como tú, listos para vivir una
                experiencia inolvidable. Desde allí, nos dirigimos a Yuquianza, atravesando paisajes majestuosos, hasta llegar a la comunidad indígena Yukiankas.
                <br></br>
                <br></br>
                <b>Inmersión Cultural y Natural</b>
                <br></br>Llegarás a Kiankus, donde montarás tu campamento en medio de la naturaleza. Aquí, sentirás la conexión total con la selva, respirando aire puro,
                rodeado de sonidos de la vida silvestre, y compartiendo historias bajo un cielo estrellado.
                <br></br>
                <br></br>
                <b>Aventura en el Corazón de la Selva</b>
                <br></br>Navegarás por ríos hermosos, mientras la selva amazónica se despliega a tu alrededor en todo su esplendor. No es solo un viaje; es una
                verdadera inmersión en la vida amazónica, compartiendo momentos únicos con las comunidades locales y disfrutando de su hospitalidad.
                <br></br>
                <br></br>
                <b>Experiencia Espiritual (Opcional)</b>
                <br></br>Para los que buscan una experiencia más profunda, tendrás la opción de participar en un ritual de Ayahuasca, una ceremonia ancestral guiada
                por chamanes locales, que promete una conexión única con lo espiritual.



              </p>

            </div>
            <div class="tab-pane fade" id="profile-mov" role="tabpanel" aria-labelledby="profile-tab">

              <br></br>
              <p></p>
              <p>
                <h4 className='text-justify text-center'>¡Vive una Noche Mágica con el Descenso a la Cueva!</h4>
                <p className='text-justify text-center'>¿Estás listo para una aventura que te llevará más allá de lo convencional? El Descenso a la Cueva es una
                  experiencia única que te conectará con la naturaleza, la cultura y la magia de la selva amazónica. Esto es lo que te espera:
                </p>
                <b>Montaje del Campamento</b>
                <br></br>Al llegar, te sumergirás en la naturaleza mientras montamos el campamento en un rincón especial de la selva. Aquí empieza la aventura, rodeado por un entorno poco visitado y lleno de vida.
                <br></br>
                <br></br>
                <b>Exploración a la Cueva</b>
                <br></br>Equipados con linternas, nos adentraremos en una cueva ancestral llena de misterio. Las formaciones naturales y el ambiente silencioso te harán sentir parte de un mundo escondido, donde solo los más aventureros se atreven a entrar.
                <br></br>
                <br></br>
                <b>Cena dentro de la Cueva</b>
                <br></br>Después de la exploración, te espera una cena inolvidable dentro de la cueva. Imagina disfrutar de una deliciosa comida en este entorno natural, mientras el eco de la cueva y la serenidad te rodean. ¡Es una experiencia que no encontrarás en ningún otro lugar!
                <br></br>
                <br></br>
                <b>Fogata Simbólica y Cantos Shuar</b>
                <br></br>Como cierre perfecto de esta jornada, nos reuniremos alrededor de una fogata simbólica dentro de la cueva, mientras los Shuar nos deleitan con sus cantos tradicionales. Este momento místico y cultural te permitirá sumergirte en sus costumbres ancestrales y crear una conexión profunda con la selva y su gente.

                <br></br>
                <br></br>
                <b>Descanso dentro de la Cueva</b>
                <br></br>Después de un día lleno de emociones, te relajarás y descansarás en el interior de la cueva, en un entorno seguro y lleno de paz, donde los sonidos de la naturaleza te arrullarán hasta dormir.



              </p>
            </div>
            <div class="tab-pane fade" id="contact-mov" role="tabpanel" aria-labelledby="contact-tab">
              <br></br>
              <p></p>
              <p>
                <h4 className='text-justify text-center'>¡Completa tu Aventura con el Ascenso de la Cueva!</h4>
                <p className='text-justify text-center'>¿Estás listo para la última etapa de esta increíble experiencia en la selva amazónica? El Ascenso de la Cueva no solo pondrá a prueba tus habilidades, sino que también te permitirá cerrar esta aventura con una conexión profunda con la naturaleza y la cultura local. Esto es lo que te espera:

                </p>
                <b>Desarmado del Campamento</b>
                <br></br>Comenzamos el día temprano, desarmando el campamento en plena selva. Mientras recogemos todo, podrás reflexionar sobre la experiencia vivida, preparándote para la gran actividad que te espera: ¡el ascenso de la cueva!
                <br></br>
                <br></br>
                <b>Desayuno Ligero</b>
                <br></br>Antes de emprender el desafío, disfrutarás de un desayuno ligero y nutritivo, ideal para cargar energías. Saborea los sabores frescos de la región mientras te preparas para lo que viene.
                <br></br>
                <br></br>
                <b>Equipamiento y Seguridad</b>
                <br></br>Con la guía de nuestros expertos, recibirás todo el equipo necesario para el ascenso y te brindaremos una charla de seguridad para garantizar que disfrutes cada momento de la aventura con total confianza.
                <br></br>
                <br></br>
                <b>El Gran Ascenso de la Cueva</b>
                <br></br>¡Aquí comienza la emoción! Atrévete a escalar las paredes naturales de la cueva, rodeado de un entorno majestuoso y lleno de adrenalina. Este desafío te llevará a nuevas alturas, literal y metafóricamente, mientras conquistas la cima y disfrutas de vistas espectaculares.
                <br></br>
                <br></br>
                <b>Exhibición de Artesanías Shuar y Regreso a Méndez</b>
                <br></br>Tras el ascenso, serás recibido por la comunidad Shuar, quienes te sorprenderán con una exhibición de sus bellas artesanías. Podrás conocer más sobre sus tradiciones, llevarte un recuerdo único y apoyar la cultura local.
                <br></br>Luego de un día lleno de emociones y descubrimientos, regresarás a Méndez con una experiencia inolvidable en tu corazón y una historia que contar.

                <br></br>
                <br></br>



              </p>
            </div>
            <div class="tab-pane fade" id="include-mov" role="tabpanel" aria-labelledby="contact-tab">
              <br></br>
              <p></p>
              <p>
                <h4 className='text-justify text-center'>¡Aventura de 3 Días/2 Noches en la Selva Amazónica! 🌿🛶</h4>
                <p className='text-justify text-center'>¡Prepárate para una experiencia única que combina aventura, cultura y naturaleza en el corazón de la selva! Este paquete te ofrece todo lo que necesitas para disfrutar al máximo de esta increíble travesía. Esto es lo que incluye:

                </p>
                <b>🍽️ Alimentación Completa</b>
                <br></br>
                Saborea la gastronomía local con 6 deliciosas comidas:
                <li>Día 1: Desayuno, almuerzo y cena</li>
                <li>Día 2: Desayuno, almuerzo y cena</li>
                <li>Día 3: Desayuno ligero para recargar energías</li>
                <br></br>
                <b>🚐 Transporte desde Méndez (ida y regreso)</b>
                <br></br>
                El transporte está incluido desde el punto de encuentro en Méndez, para que disfrutes del viaje sin preocupaciones. ¡Solo relájate y deja que la aventura comience!
                <br></br>
                <br></br>
                <b>🛶 Transporte Fluvial</b>
                <br></br>
                Navega por los ríos amazónicos en una embarcación tradicional. La selva se despliega a tu alrededor mientras avanzamos hacia comunidades remotas.
                <br></br>
                <br></br>
                <b>🧢 Equipo Completo para el Ascenso</b>
                <br></br>
                Todo el equipo necesario para tu seguridad está incluido: casco y arnés. ¡Estás en buenas manos para una aventura segura y emocionante!
                <br></br>
                <br></br>
                <b>🗺️ Guía Local con 17 Años de Experiencia</b>
                <br></br>
                Con un guía local con más de 17 años de experiencia, te aseguras de explorar la selva de la manera más auténtica y segura. ¡Conoce historias, secretos y detalles únicos de la región!
                <br></br>
                <br></br>
                <b>📜 Permisos de Ingreso a las Comunidades</b>
                <br></br>
                Todo está gestionado para que puedas ingresar y convivir con las comunidades locales, respetando su cultura y costumbres.
                <br></br>
                <br></br>
                <b>⛺ Equipo de Camping Completo</b>
                <br></br>
                Dormirás cómodo en la selva con nuestro equipo de camping, que incluye:
                <li>Carpas compartidas para 3 personas</li>
                <li>Sleeping bag</li>
                <li>Aislante para una noche confortable</li>
                <br></br>
              </p>
            </div>
            <div class="tab-pane fade" id="no-include-mov" role="tabpanel" aria-labelledby="contact-tab">
              <br></br>
              <p></p>
              <p>
                <h4 className='text-justify text-center'>¡Detalles Importantes! ❗️</h4>

                <b>NO INCLUYE</b>
                <br></br>
                <br></br>
                <li>🐎 Mulas para cargar tu equipo</li>
                <li>🍫 Snacks y bebidas durante el recorrido (lleva tus preferidos).</li>
                <li>🏕️ Carpas individuales (solo se incluyen carpas compartidas).</li>
                <li>🌿 Ceremonia de Ayahuasca (costo adicional).</li>
                <li>🚐 Transporte desde otras ciudades, como Riobamba. El punto de encuentro es en Méndez.</li>

                <br></br>
                <br></br>
                <b>Opciones Adicionales (con costo extra):</b>
                <br></br>
                <br></br>
                <li>🐴 Servicio de mulas: Si tu mochila es muy grande o pesada, puedes contratar el servicio de mulas por $50 (solo ida). ¡Una excelente opción si prefieres hacer el trayecto más ligero!</li>
                <li>🌌 Ceremonia de Ayahuasca: Si deseas participar en esta experiencia espiritual ancestral, el costo es de $60 por persona.</li>
                <li>🚶‍♂️ Camino corto para el regreso: Existe una opción más rápida para el regreso, por solo $6 por persona. Este valor no está incluido, ya que se paga directamente a los dueños del terreno. ¡Es completamente opcional y dependerá de lo que decida el grupo!</li>



              </p>
            </div>
          </div>

          <br></br>



          {/* <div className='row text-center'>
                                <div className='col-12 col-sm-6'>
                                    <h4 className='camping-letters' style={{ textTransform: 'uppercase' }}>Fecha Salida<br></br> </h4>
                                    <h5>{convertDate(tour.departure_date)}</h5>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <h4 className='camping-letters' style={{ textTransform: 'uppercase' }}>Fecha Retorno <br></br> </h4>
                                    <h5>{convertDate(tour.return_date)}</h5>
                                </div>
                            </div>

                            <br></br>
                            <div className='container text-center'>
                                <a href={`https://api.whatsapp.com/send?phone=+593${tour.contact_phone}&text=${tour.messagge_for_contact}`} target="_blank" style={{ padding: '5px' }}>
                                    <button type="button" className="btn btn-success" style={{ margin: '2vh' }}> COMPRAR  RUTA </button >
                                </a>

                                <Link to={'/tour-mensual'} style={{ padding: '5px' }}>
                                    <button type="button" className="btn btn-warning" >VOLVER A TOURS</button>
                                </Link>
                            </div> */}


        </div>
      </div >

      <br></br>
      <br></br>
      <WhatsappButtonScreen />
      <FooterScreen />
    </>

  )
}
